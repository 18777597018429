<script>
    import Layout from "../../layouts/main";
    import PageHeader from "@/components/page-header";
    import appConfig from "@/app.config";
    import ResultsGame from "@/components/patient/resultsGame.vue";
    import API from "@/api.js";
    
    //import codcfVer from "codice-fiscale-js";
    
    /**
     * User list component
     */
    export default {
      components: { Layout, PageHeader, ResultsGame},
      page: {
        title: "Dettaglio Operatore",
        meta: [
          {
            name: "description",
            content: appConfig.description,
          },
        ],
      },
      data() {
        return {
          title: "Operatori",
          items: [
            {
              text: "Operatori",
            },
            {
              text: "Dettaglio",
              active: true,
            },
          ],
          
          subject:"d",
          gamesReport:[],
        }
      },
    
      validations: {
      },
      
      computed: {
        
      },
    
      mounted() {
        // Set the initial number of items   
    
        this.callGamesReport();
    
       
      },
    
      methods: {
    
        async callGamesReport(){
          let r = await this.axios.get(API.getDoctorPlayAction.path(this.$route.params.id))
          if(r.status==200) {
            this.gamesReport = r.data
          } else {
            console.error("ERROR: ",r.data)
          }
        },
    
      },
      middleware: "authentication",
    };
    </script>
    
    <template>
      <Layout>
        <PageHeader :title="title" :items="items" />
        <ResultsGame v-bind:gamesReport="this.gamesReport" v-bind:subject="this.subject" />
      </Layout>
    </template>
    